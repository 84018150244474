import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const EsGuidesTeamBuildingkPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Team building</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_team.png"
            alt="Team building"
          />
        </div>
        <div className="page-details">
          <h1>Team building</h1>
          <h2>A guide to build your team in Eversoul.</h2>
          <p>
            Last updated: <strong>11/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Team building" />
        <p>
          Building a proper team becomes really important around Chapter 7. At
          that point, enemies will not only start to have more Combat Power than
          you, but more importantly, their teams won't be made out of random
          characters and instead they will create annoying and synergetic
          compositions.
        </p>
        <p>
          That's why around Chapter 6 you should take a good look at the
          characters you've pulled so far and decide on the direction you want
          to go. Do keep in mind that the characters you are Ascending past Epic
          aren't always the same characters you will use in your team - the game
          might give you a lot of copies of a character you don't like or don't
          want to use, but you may still need to Ascend her to use for level
          sync.
        </p>
        <p>
          If you followed our beginner’s guide and reroll suggestions, you
          should have a decent start already, in Aki, Daphne and/or Honglan.
          From there, you’d need a decent support, and a fifth member to fill
          out the team. Your goal at this point should be to build a team that
          can at least make use of the first Faction bonus - and do that you
          need to have 3 characters from the same faction in your team. This
          will provide you with a 10% boost to ATK and HP to all your
          characters.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Reroll guide"
            link="/eversoul/guides/reroll"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_reroll.jpg"
                alt="Reroll guide"
              />
            }
          />
          <CategoryCard
            title="Beginner's guide"
            link="/eversoul/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/eversoul/categories/category_beginner.jpg"
                alt="Beginner's guide"
              />
            }
          />
        </Row>
        <p>The image below shows all faction bonuses possible:</p>
        <StaticImage
          src="../../../images/eversoul/generic/team_bonuses.webp"
          alt="Rarities"
        />
        <p>
          This is a list of characters that everyone will receive for free
          during the first 2 days (we skipped Seeha as you will need around a
          week to get her):
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="chloe" enablePopover />
          <EversoulCharacter mode="icon" slug="clara" enablePopover />
          <EversoulCharacter mode="icon" slug="rebecca" enablePopover />
          <EversoulCharacter mode="icon" slug="mephistopeles" enablePopover />
        </div>
        <SectionHeader title="Good synergies" />
        <p>
          Below you will find a list of synergies we found that make some
          characters perform much better.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
        </div>
        <h5>Honglan and Talia/Vivienne/Yuria</h5>
        <p>
          Honglan's damage output and speed and mana manipulation make her the
          best support character in the game, hands down. Still, she has a
          glaring weakness when you want to use her as your main carry - she's
          slow, so it takes a long time for her to cast her skills. This is why
          you pair her with Talia or Vivi (or both) as they give her more mana
          and and speed buffs, and allow you to work around her weakness. With
          the addition of Yuria, it’s also possible to skip over Talia/Vivi
          combo since Yuria more or less does both of their jobs in one, however
          being that she’s an Angel unit she is quite a bit harder to acquire
          and ascend.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="jacqueline" enablePopover />
          <EversoulCharacter mode="icon" slug="velanna" enablePopover />
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
        </div>
        <h5>Jacqueline, Velanna and Ayame</h5>
        <p>
          Jacq teleports to the enemy backline at the start of the battle to
          wreak havoc there, putting bleed on enemies. Ayame is an amazing
          support and DPS in one, applying bleeds, reducing the enemy’s mana and
          increasing damage they receive. Velanna becomes much stronger when
          attacking targets that are already bleeding, her ultimate will deal
          more damage and stun enemies that Jacq and Ayame have applied bleeds
          to.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
        </div>
        <h5>Lize and Eve</h5>
        <p>
          Eve is an amazing support for carries, as she latches on to the unit
          with the highest attack in your party, taking some of their damage for
          them, provides them with significant buffs, and even feeds them mana
          to speed up their rate of destruction. One of the most notable
          partners for Eve is of course Lize, who can shred through enemies even
          faster with all the attack fed to her by Eve’s bond.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="mephistopeles" enablePopover />
          <EversoulCharacter mode="icon" slug="jiho" enablePopover />
          <EversoulCharacter mode="icon" slug="beleth" enablePopover />
        </div>
        <h5>Aki/Linzy, Mephi and Jiho/Beleth</h5>
        <p>
          The pairing of Mephi and Jiho is a great support for either Aki or
          Linzy, or both at the same time. Both Mephi and Jiho increase your
          team’s crit rate, which works to great effect with Aki and Linzy’s
          assassination-type attacks, allowing them to jump around the
          battlefield picking off targets with ease. Mephi also brings defense
          and accuracy reduction, further enhancing your team’s ability to both
          do more damage and survive. Beleth is a great replacement for Jiho if
          you get her built too, as she does basically the same job but slightly
          better.
        </p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="claudia" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
        </div>
        <h5>Claudia plus supports</h5>
        <p>
          A well-built Claudia is devastating to the enemy team, especially with
          a good support crew. As long as you have a solid wall of tanks for her
          to hide behind, all she has to do is get off her main and ultimate
          combo and watch the enemy disappear. Yuria and Eve together can boost
          pretty much any carry to absurd heights, so when adding them to
          Claudia’s incomparable AoE capabilities it is easy to see why this
          combination works so well in 5v5 combat.
        </p>
        <SectionHeader title="Meta team comps" />
        <p>
          Here are some of the most commonly used teams. Split between early and
          late game, you can also mix and match units as needed, as team
          building isn’t a specifically static thing in Eversoul.
        </p>
        <h5>Early Game</h5>
        <h6>Fairy + Honglan</h6>
        <p>Aka the most OP comp in the game.</p>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="chloe" enablePopover />
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
          <EversoulCharacter mode="icon" slug="naiah" enablePopover />
        </div>
        <p>
          This team works very well in almost all circumstances, and is quite
          well-rounded. It even continues to be strong in the late game, so it’s
          a good one to invest in. Chloe is easily the best solo-tank in the
          game, as she comes with an AoE taunt and a second life mechanic in her
          absurd regen when she gets low once per battle.
        </p>
        <ul>
          <li>
            Daphne is a great member to slot in, or even just to replace Chloe
            if she’s got some investment. She works great at low ascensions, and
            her damage reflecting mechanic can also wipe the enemy’s frontline
            quite effectively!
          </li>
          <li>
            In the back line, Talia brings passive defensive buffs, heals,
            cleanse, dispel, and serves as a mana battery for her team. Her kit
            is so loaded that she’s the easiest support to slot in for universal
            usage. And of course Honglan, the support/DPS, should be able to
            keep the enemy in check while DPSing them down.{' '}
          </li>
          <li>
            Vivi provides speed boosts to keep your team faster than the enemy,
            and acts as a decent sub-dps.
          </li>
          <li>
            Naiah provides amazing CC in the form of AoE sleeps, respectable
            damage, and the ability to act as a diversion unit if her artifact
            is at Legendary+, as she will sleep enemies that attack her and
            become untargetable for a time. This allows her to bait things such
            as Adrianne’s pull (Naiah-bomb) or Jacq’s jump, putting them to
            sleep and giving her or her team time to deal with the threat.
          </li>
        </ul>
        <h6>Undead Team</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="petra" enablePopover />
          <EversoulCharacter mode="icon" slug="velanna" enablePopover />
          <EversoulCharacter mode="icon" slug="jacqueline" enablePopover />
          <EversoulCharacter mode="icon" slug="prim" enablePopover />
          <EversoulCharacter mode="icon" slug="garnet" enablePopover />
          <EversoulCharacter mode="icon" slug="rebecca" enablePopover />
        </div>
        <p>
          The Undead faction has a lot of synergy between its members - though
          in pushing regular PvE content, you’ll want to avoid the single target
          DoT-focused souls. Petra is a great tank, normally better solo but
          with Velanna as a sub-tank and DPS, and Jacq jumping in to disrupt the
          back line, the three of them synergize well. Add in Prim for heals and
          Garnet for lifesteal and defense debuffs to make the enemies die
          faster, and you’ve got a solid team!
        </p>
        <p>
          If you don’t have Garnet, Rebecca is also a decent substitute, as she
          will speed boost Jacq, and increase the bleed damage dished out by
          both Jacq and Velanna.
        </p>
        <h6>Human Team</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="claire" enablePopover />
          <EversoulCharacter mode="icon" slug="aki" enablePopover />
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="jiho" enablePopover />
          <EversoulCharacter mode="icon" slug="beleth" enablePopover />
          <EversoulCharacter mode="icon" slug="catherine" enablePopover />
          <EversoulCharacter mode="icon" slug="mephistopeles" enablePopover />
        </div>
        <p>
          A full Human team is easy to build, as there are many good options and
          a decent amount of synergy to work with. Claire is a strange kind of
          tank, as she tends to desert her team and dive deep into enemy
          territory, and lacks a taunt. Pairing her with units that are able to
          get around the battlefield easily such as Aki and Linzy is quite
          helpful in that regard.
        </p>
        <ul>
          <li>
            Aki is the main DPS in the Human team, when well invested she can
            delete the entire enemy team once she gets her main/ultimate combo
            rolling.{' '}
          </li>
          <li>
            Linzy is a decent partner or replacement, as she provides slightly
            less kill capability in return for significantly increased
            survivability via dodges. She can even be used to taunt annoying
            enemies such as Adrianne, preventing her from pulling your squishy
            backliners to their doom.
          </li>
          <li>
            In the backline, Jiho is often the preferred support, as her heals
            can reach Claire when she darts off deep into enemy territory, plus
            she provides great supportive buffs for your DPS.{' '}
          </li>
          <li>
            Beleth is also an amazing support, however she will jump around the
            map a lot and may get herself killed, so she will require a decent
            amount of investment for the inevitable jump to Claire deep behind
            enemy lines.
          </li>
          <li>
            Mephi is a great option, providing further DPS increases and has the
            ability to support your Linzy with accuracy debuffs that enhance her
            evasion capability.{' '}
          </li>
          <li>
            Catherine is also a common support unit used here, since her shields
            and invuln can often be invaluable in keeping your team (sans
            Claire) alive.
          </li>
        </ul>
        <h6>Beast Team</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="seeha" enablePopover />
          <EversoulCharacter mode="icon" slug="mica" enablePopover />
          <EversoulCharacter mode="icon" slug="soonie" enablePopover />
          <EversoulCharacter mode="icon" slug="xiaolian" enablePopover />
          <EversoulCharacter mode="icon" slug="clara" enablePopover />
          <EversoulCharacter mode="icon" slug="tasha" enablePopover />
        </div>
        <p>
          Beast is in a strange place, as there aren’t any particularly
          stand-out units, most of them work fine within a team comprised of
          Beast units. The faction is also almost entirely melee, which makes
          team building a bit strange when trying to incorporate ranged units,
          or avoid being killed by a single enemy with a mid-sized AoE.
          Beast-centric teams are likely the weakest of the factions, and mostly
          reserved for use in Beast Gate rather than in Battlefront.
        </p>
        <ul>
          <li>
            The duo of Mica and Seeha is basically a staple when building beast
            teams, although they are not that amazing, they get the job done.
            When together, they can even work decently as the tanks for your
            team, somewhat rendering Soonie replaceable.{' '}
          </li>
          <li>
            Soonie is a great tank however, having an AoE on-demand taunt,
            increasing the healing output of any heal over time from your
            healers (looking at Clara here), and decreasing the attack of the
            enemies she hits with her skills.
          </li>
          <li>
            Clara is the support of choice if running any ranged units, as
            Seeha’s heals will not reach your backline. She also provides
            cleanses and CC immunity, on top of a teamwide defense boost, so
            she’s quite a solid option.
          </li>
          <li>
            For DPS, there is the option to bring more melee into the fight in
            the form of Xiao, who is a particularly selfish DPS but performs
            decently well when invested.
          </li>
          <li>
            Tasha is a newer addition, and she hugely benefits the melee-centric
            beast faction, as she buffs units who are close to enemies, and
            gains buffs for herself the more of your units are close to the
            enemy. She is a ranged DPS, but leaving her alone in the backline
            doesn’t tend to be a problem as she gains significant defensive
            boosts from her self-buffs.
          </li>
          <li>
            Haru and Aira are special mentions, as they provide your team with
            buffs, or debuff the enemy, either way increasing the damage output
            of your team. As is the trend with the Beast faction, they are, of
            course, melee units.
          </li>
        </ul>
        <h5>Late Game</h5>
        <p>
          When building teams for late game, it is mostly a matter of sticking
          parts of teams together that work against the enemy comp. There aren’t
          any specifically hard-set teams, and a lot of the early game teams
          will still work. Keeping the synergies in mind that were listed at the
          beginning of this guide is key to building late-game teams that
          counter whatever the game throws at you in the form of enemy teams.{' '}
        </p>
        <p>
          There are of course characters of note that can be used to help with
          specific enemy setups, or just improve your team overall, to mention a
          few:{' '}
        </p>
        <h6>Yuria</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
        </div>
        <p>
          As previously mentioned, Yuria is the gold standard support in the
          game. Getting her will be hard without a focus banner, but she’s very
          much worth it as she makes every team she’s on better just by
          existing. She can take the place of basically any healer and do their
          job better in most cases. She’s Angel faction too, which makes doing
          this even simpler due to faction bonus. Definitely recommended to
          invest in her if possible!
        </p>
        <h6>Daphne</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
        </div>
        <p>
          Where Yuria is the gold standard support, Daphne holds the title for
          tanks. While she doesn’t have the same bulkiness as some of the other
          tanks, and she also lacks a taunt, she more than makes up for it in
          other ways. The biggest benefit of Daphne is her damage share, helping
          her team stay alive by absorbing some of the damage they take. She
          then stores all this and releases it on the enemy with her main, which
          quite often results in the swift death of most, if not all, of the
          enemy team, depending on their positioning and how long you wait for
          her to absorb damage before releasing it. Any team is happy to have
          her along.
        </p>
        <h6>Eve</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
        </div>
        <p>
          If you’re going with a single hard carry to wipe out the enemy team,
          attaching Eve to her is going to improve her performance
          significantly. Eve will take some of the damage for the unit with the
          highest attack on your team and provide them with buffs and mana that
          will aid them in their job as a carry. Putting her with units such as
          Claudia, Lize, Aki, or even Jacqueline can turn the fight in your
          favour quickly!
        </p>
        <h6>Adrianne</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="adrianne" enablePopover />
        </div>
        <p>
          While she isn’t considered a must-have unit anymore, Adrianne does
          have one very important thing she can do that no other unit can: she
          will pull the furthest enemy unit from her into the center of your
          team. This will usually be a squishy backliner which your team can
          then dispose of quickly, turning a fight into a 5v4 right at the start
          of battle! Placing Adrianne wisely, even at low investment, can help
          remove problematic enemy units and help you win fights.
        </p>
        <h6>Jacqueline</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="jacqueline" enablePopover />
        </div>
        <p>
          Basically Adrianne’s opposite, Jacq is the only unit in the game that
          immediately jumps into the enemy’s backline and starts doing murder to
          the squishy units hiding back there. To survive this endeavor she does
          require quite a lot of investment, but even if she dies before killing
          her target, she can still aid your team by acting as a distraction.
          She jumps to the unit furthest from her, stuns them and stealths while
          attacking for a brief period. When she comes out of stealth, the enemy
          unit will turn around and stop attacking your other units, taking
          pressure off them and possibly allowing you to take care of other
          targets without being killed.
        </p>
        <h6>Honglan/Ayame</h6>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="honglan" enablePopover />
          <EversoulCharacter mode="icon" slug="ayame" enablePopover />
        </div>
        <p>
          Both of these units serve a similar purpose, though they perform it
          somewhat differently. Honglan of course slows down the enemy team just
          by existing on the field, which can help a significant amount in
          surviving early on in a fight. Ayame has a strong Charm effect that
          forces an enemy unit to attack their teammates for a period of time,
          which also alleviates some of the damage your team would take. They
          both have mana control in common, draining the enemy team’s mana,
          allowing your team to use their main skills faster and more often than
          the enemy team, which of course means doing more damage and making
          surviving easier.
        </p>
        <p>
          The biggest difference will be seen in the late game, where Ayame’s
          damage potential is significantly higher than Honglan’s, allowing her
          to both support and kill enemies. This tends to be much more effective
          since in the late stages it often comes down to ‘kill or be killed’
          when the enemy has a significant level advantage over your team. Ayame
          also has a bind that activates at the start of a match which is
          particularly helpful when dealing with pesky Akis.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesTeamBuildingkPage;

export const Head: React.FC = () => (
  <Seo
    title="Team building | Eversoul | Prydwen Institute"
    description="A guide to build your team in Eversoul."
    game="eversoul"
  />
);
